:root {
  --background-darker: #141A1F;
  --background-dark: #1E272E;
  --background-light: #485460;
  --text-lighter: white;
  --text-light: #D2DAE2;
  --text-dark: #485460;
  --text-darker: #1E272E;
  --chart-altitude: #47535F;
  --chart-speed: #0CA9E2;
  --chart-power: #00D7D5;
  --chart-cadence: #FFA801;
  --chart-heartrate: #EF5777;
  --light-frame: rgb(249,249,249);
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  font-family: 'Montserrat', serif;
}

.rotate90 {
  animation: rotation 3s linear;
  transform: perspective(600px) rotateY(30deg) rotateX(30deg);
}

.rotate-left {
  opacity: 0.5;
  transform: perspective(600px) rotateY(-30deg) rotateX(30deg);
}

.animate-left{
  animation: moveLeft 3s ease-in-out;
}

.animate-right{
  animation: moveRight 3s ease-in-out;
}

body.basic-properties{
  font-family: 'Montserrat', serif;
}
.font-link {
  font-family: 'Montserrat', cursive;
}

.navbar-nav {
  margin-left: auto;
}

.nav-link{
  color: black;
  font-weight: 900;
  font-style: italic;
}

.bck-image{
  /*background-image: url("images/final/banner-kolesarji.jpg");*/
  background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url("images/final/banner-kolesarji.jpg");
  background-size: cover;
  background-position: center;
}

.background-video{
  height: 550px;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.background-video-gradient{
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1.0));
  display: flex;
  align-items: end;
}

.header-canvas{
  height: 550px;
  font-size: 30px;
  color: white;
  margin: 0;
  padding-top: 250px;
  padding-bottom: 64px;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.text-body{
  font-size: 15px;
}

/* .bg-* je v dokumentaciji za bootstrap naveden kot utility class, ki določa background */
.bg-transparent{
  background-color: transparent;
}

/* ZATEMNJENA SEKCIJA, KAMOR PRIDe BESEDILO*/
div.text-frame{
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
}

/* uvodna sekcija*/
div.section-overview{
  min-height: 400px;
  display: grid;
  justify-content: center;
  align-items: center;
}

/*RIDE*/
div.section-ride{
  background-image: url("images/final/ride.jpg");
  background-size: cover;
  background-position: left;
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 800px;
  padding-top: 50px;
  padding-bottom: 50px;
}

/*WORKOUT*/
div.section-workout-upper{
  justify-content: center;
  align-items: center;
  min-height: 500px;
  padding-top: 50px;
  padding-bottom: 25px;
}

/*WORKOUT*/
div.section-workout-lower{
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  padding-top: 0px;
  padding-bottom: 0px;
}

/* ANALYSE - ZGORNJI DEL */
div.section-analysis{
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)), url("images/final/analysis.jpg");
  background-size: cover;
  background-position: center right;
  display: flex;
  justify-content: center;
  align-items: end;
  min-height: 750px;
  padding-top: 50px;
  padding-bottom: 0px;
}

div.section-analysis-small-screen{
  background-color: #F1F2F6;
  padding-bottom: 50px;
}

.video-absolute-position{
  filter:opacity(100%);
  position: absolute;
  border-radius: 20px;
  z-index: 999;
  right: 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  width: 100%;
  max-width: 250px;
}

div.absolute-position{
  position: absolute;
  top: 150px;
  left: 96px;
  width: 350px;
}



div.absolute-position-small-screen{
  position: absolute;
  top: 250px;
  right: 48px;
  width: 300px;
}

div.section-analysis-shadow{
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.5);
  min-height: 350px;
}

/* USER EXPERIENCE */
div.section-user-experience{
  min-height: 450px;
  display: grid;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

div.section-user-experience-lower{
  background-image: url("images/final/phone-on-bike.jpg");  /* najboljša sta sectors-3 in sectors-11 */
  background-size: cover;
  background-position: center;
  display: grid;
  justify-content: left;
  align-items: start;
  padding-bottom: 24px;
  min-height: 700px;
}

div.section-sectors{
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)), url("images/final/sectors.jpg");
  background-size: cover;
  background-position: right;
  display: grid;
  justify-content: right;
  align-items: end;
  padding-bottom: 24px;
  min-height: 700px;
}

div.section-sensors-upper{
  min-height: 350px;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: #D2DAE1;
  padding-top: 50px;
  padding-bottom: 50px;
}

div.section-sensors-lower{
  background-image: url("images/final/sensors.jpg");  /* najboljša sta sectors-3 in sectors-11 */
  background-size: cover;
  background-position: center;
  display: grid;
  justify-content: left;
  align-items: start;
  margin-top: 48px;
  margin-bottom: 48px;
  height: 500px;
}

/* section 6 -> sectors
 */
/* section 7 -> sensors*/

/* HIGHLIGHTS */
div.section-highlights{
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 700px;
  padding-top: 50px;
  padding-bottom: 0;
}

.section-framed{
  clip-path: inset(0 round 30px);
  margin-top: 30px;
  margin-bottom: 30px;
}

/* GET started */
div.section7{
  background-color: var(--light-frame);
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  padding-top: 50px;
  padding-bottom: 0px;
}

div.ride-section1{
  min-height: 350px;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: #D2DAE1;
  padding-top: 50px;
  padding-bottom: 50px;
}

div.ride-section1-background{
  background-image: url("images/final/sectors.jpg");  /* najboljša sta sectors-3 in sectors-11 */
  background-size: cover;
  background-position: center;
  display: grid;
  justify-content: right;
  align-items: end;
  padding-bottom: 24px;
  min-height: 700px;
}

div.ride-sensors-background{
  background-image: url("images/final/sensors.jpg");  /* najboljša sta sectors-3 in sectors-11 */
  background-size: cover;
  background-position: left;
  display: grid;
  justify-content: left;
  align-items: start;
  padding-bottom: 24px;
  min-height: 600px;
}

div.footer{
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
  padding-bottom: 50px;
}

div.card{
  border-radius: 10px;
  border: none;
  min-height: 80px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

div.overview-card{
  height: 180px;
  border-radius: 10px;
  border: none;
  display: grid;
  margin: 16px 8px;
}

div.overview-card-segment-1{
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
}

div.overview-card-segment-2{
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: center;
}

div.section-break{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-frame);
  color: white;
  margin-top: 48px;
  margin-bottom: 48px;
  height: 128px;
}

.card-image-size{
  max-height: 64px;
  max-width: 64px;
}

.activity-card-image-size{
  max-height: 32px;
  max-width: 32px;
}

p{
  font-weight: 500;
  padding-right: 16px;
  padding-left: 16px;
  color: var(--text-dark)
}

p.title{
  font-size: 45px;
  font-weight: 900;
  font-style: italic;
  line-height: 1.1em;
  color: black;
}

p.title-section{
  font-size: 45px;
  font-weight: 900;
  line-height: 1.1em;
  font-style: italic;
}

p.section-break{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-darker);
  color: white;
  margin-top: 48px;
  margin-bottom: 48px;
  height: 128px;
}

p.title-small{
  font-size: 25px;
  font-weight: 900;
  font-style: italic;
  color: black;
}

p.title-footer{
  font-size: 14px;
  font-weight: 900;
  color: #D2DAE1;
  margin-top: 48px;
  margin-bottom: 8px;
}

p.light-color{
  color: var(--text-lighter);
}

/* BESEDILO NA NASLOVNI SLIKI*/
p.lyrics{
  padding: 0;
  margin: 3px;
}

p.overview-card-title{
  font-weight: 900;
  font-style: italic;
  color: var(--background-light);
}

container{
  background-color: black;
}

.p-card-title{
  font-weight: 800;
  font-size: 15px;
  font-style: italic;
  padding: 0;
  margin: 0;
}

.p-card-body{
  padding: 0;
  margin: 0;
  color: var(--background-light)
}

.p-card-value{
  padding: 0;
  margin: 0;
  color: black;
  font-weight: 900;
}

.table-footer{
  margin-top: 0;
}

.horizontal-list{
  overflow-x: auto;
  padding: 0;
}
ul.highlights {
  width: 1720px;
  height: 600px;
  margin: 0;
  padding: 0;
}
li.highlights {
  width: 280px;
  height: 550px;
  margin: 0;
  padding: 0;
  display: inline;
  float: left;
}

/* EPIC RIDES */
div.section-epic-rides{
  background-color: var(--light-frame);
  display: grid;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 0;
}

ul.epic-rides {
  margin: 32px 0 0;
  padding: 0;
}
li.epic-rides {
  width: 500px;
  height: 330px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0;
  display: inline;
  float: left;
}

div.epic-rides-background{
  clip-path: inset(0 round 20px);
  diplay: flex;
  align-items: end;
  justify-content: end;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

p.epic-rides-title{
  font-weight: 900;
  text-align: left;
  font-size: 19px;
  padding: 0px;
  margin: 0;
}

p.epic-rides-quantity{
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  padding: 0px;
  margin: 0px 0px 0px 8px;
}

p.epic-rides-value{
  font-weight: 700;
  text-align: left;
  font-size: 16px;
  padding: 0px 0px 0px 8px;
  margin: 0px;
}

a.link{
  font-weight: 500;
  font-size: 12px;
  color: #808E9B;
  background-color: transparent;
  border: none;
  text-underline: none;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: white;
}

Button.link{
  font-weight: 500;
  font-size: 12px;
  color: #808E9B;
  background-color: transparent;
  border: none;
}

Button.link:hover{
  background-color: transparent;
  color: white;
}

Button.link:active{
  background-color: transparent;
  border: none;
}

.link-button{
  border-radius: 24px !important;
  background-color: rgba(15,186,248,0.2) !important;
  color: var(--text-lighter) !important;
  border-width: 2px !important;
  border-color: #0FBAF8 !important;
  font-size: 21px !important;
  font-family: 'Montserrat';
  font-weight: 1000 !important;
  font-style: italic;
  width: 240px;
  height: 48px;
}

.map{
  margin-top: 30px;
  margin-bottom: 30px;
}

.chart-button{
  border-radius: 15px !important;
  background-color: gray !important;
  borderWidth: 0 !important;
  border-color: gray !important;
  font-size: 10px !important;
  width: 70px;
  margin-left: 0px !important;
  margin-right: 0px;
}

.altitude-button{
  border-color: var(--chart-altitude) !important;
  color: black !important;
  background-color: var(--chart-altitude) !important;
}


.speed-button{
  border-color: var(--chart-speed) !important;
  color: black !important;
  background-color: var(--chart-speed) !important;
}

.heartrate-button{
  border-color: var(--chart-heartrate) !important;
  color: black !important;
  background-color: var(--chart-heartrate) !important;
}

.power-button{
  border-color: var(--chart-power) !important;
  color: black !important;
  background-color: var(--chart-power) !important;
}

.cadence-button{
  border-color: var(--chart-cadence) !important;
  color: black !important;
  background-color: var(--chart-cadence) !important;
}

.fit-parent{
  max-width:100%;
  max-height:100%;
  vertical-align: middle;
}

td{
  text-align: center;
  vertical-align: middle;
}

.td-activity-card{
  text-align: left;
}

Result
EDIT ON

/* SlideShow */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: var(--background-light);
}

.slideshowDot.active {
  background-color: var(--text-lighter);
}

@keyframes rotation {
  from {
    transform: perspective(600px) rotateY(0deg) rotateX(0deg);
  }
  to {
    transform: perspective(600px) rotateY(30deg) rotateX(30deg);
  }
}

@keyframes moveLeft {
  from {
    transform: translateX(20px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes modeRight {
  from {
    transform: translateX(-20px);
  }
  to {
    transform: translateX(0px);
  }
}